import {useEffect, useState} from "react";

import imgSrc from '../../img/plano.png';
import "./IndexPage.css"
import Zonas from "../../libs/Zonas";

export default function IndexPage() {
    const [widthImage, setWidthImage] = useState(3753);
    const [heightImage, setHeightImage] = useState(1400);
    const [currentItem, setCurrentItem] = useState(-1);
    const [viewBox, setViewBox] = useState({x: 0, y: 0, w: widthImage, h: heightImage});
    useEffect(() => {
        const image = new Image();
        image.src = imgSrc; // Asegúrate de ajustar la ruta a la imagen

        image.onload = () => {
            setWidthImage(image.width);
            setHeightImage(image.height);
        };
    }, []);

    useEffect(() => {
        setViewBox((ov) => ({...ov, w: widthImage, h: heightImage}))
    }, [widthImage, heightImage]);

    const zonas = Zonas
        .entrada('entrada', 114, 78, 1443, 1803, 8)
        .addZona('Zona D - C17', (ruta) =>
            ruta.nextTop('ruta 1', 919, true)
                .nextRight('ruta 2', 35)
                .destinoRight('destino', 139, 99)
        )
        .addZona('Zona D - D4', (ruta) =>
            ruta.nextTop('ruta 1', 1030, true)
                .nextLeft('ruta 2', 80)
                .nextTop('ruta 2', 230)
                .destinoTop('destino', 121, 99, 20)
        )
        .addZona('Zona A - A69', (ruta) =>
            ruta.nextTop('ruta 1', 270, true)
                .nextLeft('ruta 2', 770)
                .nextTop('ruta 3', 50)
                .nextLeft('ruta 4', 10)
                .destinoLeft('destino', 45, 50)
        )
        .addZona('Zona A - A12', (ruta) =>
            ruta.nextTop('ruta 1', 270, true)
                .nextLeft('ruta 2', 220)
                .nextTop('ruta 3', 205)
                .nextLeft('ruta 4', 365)
                .nextTop('ruta 4', 10)
                .destinoTop('destino', 30, 50)
        )
        .addZona('Zona B - B272A', (ruta) =>
            ruta.nextTop('ruta 1', 1030, true)
                .nextLeft('ruta 2', 80)
                .nextTop('ruta 3', 135)
                .nextLeft('ruta 4', 385)
                .nextTop('ruta 5', 140)
                .nextRight('ruta 6', 15)
                .nextTop('ruta 7', 10)
                .destinoTop('destino', 40, 40)
        )
    const items = zonas.create()
    const currentZone = currentItem > -1 ? items[currentItem] : null

    return (<div className="MapTrace flex h-screen">
        <div className="bg-gray-800 text-white w-64 h-full">
            <div className="p-6">
                    <h1 className="text-2xl font-bold">Rutas</h1>
            </div>
            <nav className="mt-10">
                <ul className="lista">
                    {items.map((item, index) => (
                        <li key={index} onClick={() => setCurrentItem(index)}
                            className={`block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 ${(currentItem === index ? `selected lista-item` : 'lista-item')}`}>
                            {item.title}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>

        <div className="flex-1 p-6 bg-gray-100">
            <svg id="main" xmlns="http://www.w3.org/2000/svg" className="border"
                 viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
                 width="100%">
                <image xlinkHref={imgSrc}
                       style={{width: '100%'}}/>
                {!!currentZone && (items[currentItem].items.map(({dataDir, ...item}, index) => (
                    <rect key={index} data-index={index} data-dir={dataDir} {...item}/>
                )))}
            </svg>
        </div>
    </div>);
}