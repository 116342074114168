import {RutaItem} from "./types";

export default class Ruta {
    title: string;
    grosor: number;
    start: RutaItem;
    rutas: RutaItem[];

    constructor(title: string, w: number, h: number, x: number, y: number, g: number = 10) {
        this.title = title;
        this.grosor = g;
        this.start = this.createItem(title, w, h, x, y, 'start');
        this.rutas = [this.start];
    }

    static start(title: string, w: number, h: number, x: number, y: number, g: number = 10): Ruta {
        return new Ruta(title, w, h, x, y, g);
    }

    createItem(title: string, width: number, height: number, x: number, y: number, d: string): RutaItem {
        return {
            title,
            x,
            y,
            width,
            height,
            dataDir: d,
            className: 'image-mapper-shape dashed'
        };
    }

    lastRuta(): RutaItem {
        const index = this.rutas.length - 1;
        return this.rutas[index];
    }

    nextTop(title: string, w: number, center: boolean = false): this {
        return this.addRutaTop(title, this.grosor, w, center);
    }

    nextRight(title: string, w: number, center: boolean = false): this {
        return this.addRutaRight(title, w, this.grosor, center);
    }

    nextLeft(title: string, w: number, center: boolean = false): this {
        return this.addRutaLeft(title, w, this.grosor, center);
    }

    addRutaTop(title: string, w: number, h: number, center: boolean = false, hw: number = 0): this {
        const lastItem = this.lastRuta();
        let x = lastItem.x + hw;
        if (center) {
            x += (lastItem.width / 2) - (w / 2);
        }
        if (lastItem.dataDir === 'r') {
            x += lastItem.width;
            x -= this.grosor;
        }
        const y = lastItem.y - h;
        const item = this.createItem(title, w, h, x, y, 't');
        this.rutas.push(item);
        return this;
    }

    addRutaDown(title: string, w: number, h: number, center: boolean = false, hw: number = 0): this {
        const lastItem = this.lastRuta();
        const x = (lastItem.x + (center ? ((lastItem.width / 2) - (w / 2)) : 0)) + hw;
        const y = lastItem.y - h;
        const item = this.createItem(title, w, h, x, y, 'b');
        this.rutas.push(item);
        return this;
    }

    addRutaRight(title: string, w: number, h: number, center: boolean = false, vw: number = 0): this {
        const lastItem = this.lastRuta();
        const x = lastItem.x + lastItem.width;
        const y = (lastItem.y + (center ? ((lastItem.height / 2) - (h / 2)) : 0)) + vw;
        const item = this.createItem(title, w, h, x, y, 'r');
        this.rutas.push(item);
        return this;
    }

    addRutaLeft(title: string, w: number, h: number, center: boolean = false, vw: number = 0): this {
        const lastItem = this.lastRuta();
        const x = lastItem.x - w;
        const y = (lastItem.y + (center ? ((lastItem.height / 2) - (h / 2)) : 0)) + vw;
        const item = this.createItem(title, w, h, x, y, 'l');
        this.rutas.push(item);
        return this;
    }

    destinoRight(title: string, w: number, h: number, vw: number = 0): this {
        return this.addRutaRight(title, w, h, true, vw);
    }

    destinoTop(title: string, w: number, h: number, hw: number = 0): this {
        return this.addRutaTop(title, w, h, true, hw);
    }

    destinoLeft(title: string, w: number, h: number, vw: number = 0): this {
        return this.addRutaLeft(title, w, h, true, vw);
    }

    clone(): Ruta {
        return new Ruta(this.start.title, this.start.width, this.start.height, this.start.x, this.start.y, this.grosor);
    }

    area(): { x: number; y: number } {
        let minX = Infinity;
        let maxY = 0;
        for (const item of this.rutas) {
            minX = Math.min(minX, item.x);
            maxY = Math.max(maxY, item.y);
        }
        if (minX === Infinity) {
            minX = 0;
        }
        return {x: minX, y: maxY};
    }

    toArray(): RutaItem[] {
        return this.rutas;
    }
}
