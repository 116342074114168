import Ruta from "./Ruta";
import {RutaItem, ViewBox, Zona} from "./types";

export default class Zonas {
    zonas: Zona[];
    entrada: Ruta | null;

    constructor() {
        this.zonas = [];
        this.entrada = null;
    }

    static build(title: string, w: number, h: number, x: number, y: number, g: number = 10): Zonas {
        return new Zonas();
    }

    static entrada(title: string, w: number, h: number, x: number, y: number, g: number = 10): Zonas {
        const zona = new Zonas();
        zona.setEntrada(title, w, h, x, y, g);
        return zona;
    }

    setEntrada(title: string, w: number, h: number, x: number, y: number, g: number = 10): this {
        this.entrada = Ruta.start(title, w, h, x, y, g);
        return this;
    }

    addZona(title: string, callback?: (start: Ruta) => Ruta): this {
        if (!this.entrada) {
            throw Error("Ingrese una entrada .setEntrada() ")
        }
        const start = this.entrada.clone();
        const ruta = typeof callback === 'function' ? callback(start) : start;
        const zona: Zona = {
            title,
            items: Array.isArray(ruta?.toArray()) ? ruta?.toArray() || [] as RutaItem[] : [] as RutaItem[],
            ruta: ruta,
            area() {
                return ruta?.area() || {x: 0, y: 0};
            }
        };
        this.zonas.push(zona);
        return this;
    }

    area(): { x: number; y: number } {
        let minX = Infinity;
        let maxY = 0;

        for (const zone of this.zonas) {
            for (const item of zone.items) {
                minX = Math.min(minX, item.x);
                maxY = Math.max(maxY, item.y);
            }
        }
        if (minX === Infinity) {
            minX = 0;
        }
        return {x: minX, y: maxY};
    }

    create(): Zona[] {
        return this.zonas;
    }

    createSvg(imgSrc: string, index: number, viewBox: ViewBox): SVGSVGElement {
        const currentZone = index > -1 ? this.zonas[index] : null;
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

        svg.setAttribute("id", "main");
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svg.setAttribute("class", "border");
        svg.setAttribute("viewBox", `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        svg.setAttribute("width", "100%");

        const image = document.createElementNS("http://www.w3.org/2000/svg", "image");
        image.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", imgSrc);
        image.style.width = "100%";

        svg.appendChild(image);

        if (currentZone) {
            currentZone.items.forEach(({dataDir, ...item}, index) => {
                const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                rect.setAttribute("data-index", index.toString());
                rect.setAttribute("data-dir", dataDir);
                Object.keys(item).forEach(key => {
                    rect.setAttribute(key, item[key as keyof typeof item]!.toString());
                });
                svg.appendChild(rect);
            });
        }
        return svg;
    }
}
